<template>
  <div class="recorder">
    <h6 class="recorder-h" v-if="memory == 1">点击词汇 开始跟读</h6>
    <h6 class="recorder-h" v-else>点击句子 开始跟读</h6>
    <div class="recorder-list" v-if="lrcList.length > 0 && this.loading">
      <div
        class="recorder-list-item"
        v-for="(item, index) in lrcList"
        :key="index"
        @click="clickItem(index)"
      >
        <template v-if="item.isOpen">
          <div class="recorder-list-item-read">
            <div class="title">
              <span class="number">{{ index + 1 + "/" + lrcList.length }}</span>
              <template v-if="item.isReading">
                <div class="box box-media">
                  <ul>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                  </ul>
                </div>
              </template>
              <template v-else-if="item.isAudioing">
                <div class="box box-audio">
                  <ul>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                  </ul>
                </div>
              </template>
              <template v-else>
                <div class="box">
                  <ul>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                    <p class="box-p"></p>
                  </ul>
                </div>
              </template>
            </div>
            <div class="content">
              <p v-if="item.evaWord" class="sentence" v-html="item.evaWord"></p>
              <p v-else class="sentence" v-html="item.word"></p>
            </div>
            <div class="fun">
              <!--播放原音-->
              <template v-if="item.isReading">
                <div class="fun-box fun-media" @click="clickReadPause">
                  <i-pause-one theme="outline" />
                  <p>播放中</p>
                </div>
              </template>
              <template v-else>
                <div
                  v-if="item.isRecodering || item.isAudioing"
                  class="fun-box fun-media"
                >
                  <i-play theme="outline" />
                  <p>播放原音</p>
                </div>
                <div v-else class="fun-box fun-media" @click="clickReadPlay">
                  <i-play theme="outline" />
                  <p>播放原音</p>
                </div>
              </template>
              <!--录音-->
              <template v-if="item.isRecodering">
                <div class="fun-box fun-recording" @click="stopRecordAudio">
                  <i-voice theme="outline" />
                  <p>点击停止</p>
                </div>
              </template>
              <template v-else>
                <div
                  class="fun-box fun-recording"
                  @click="startRecordAudio(item.isReading, item.isAudioing)"
                >
                  <i-voice theme="outline" />
                  <p>点击录音</p>
                </div>
              </template>
              <!--播放录音/录音分值 clickItemPauseRecorder-->
              <template v-if="item.userScore && item.userScore > 0">
                <div
                  v-if="
                    item.audioRecordUrl == '' &&
                    (item.isRecodering || item.isReading)
                  "
                  class="fun-box fun-score"
                >
                  <span class="i-score">{{ item.userScore }}</span>
                  <p>播放录音</p>
                </div>
                <div
                  v-else-if="item.isAudioing"
                  class="fun-box fun-score"
                  @click="clickItemPauseRecorder"
                >
                  <span class="i-score">{{ item.userScore }}</span>
                  <p>播放中</p>
                </div>
                <div
                  v-else
                  class="fun-box fun-score"
                  @click="clickItemPlayRecorder"
                >
                  <span class="i-score">{{ item.userScore }}</span>
                  <p>播放录音</p>
                </div>
              </template>
              <template v-else>
                <div class="fun-box fun-score">
                  <span class="i-score i-score1"></span>
                  <p></p>
                </div>
              </template>
            </div>
            <div
              class="read-box"
              :id="'mxy' + index"
              :ref="'mxy' + index"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="recorder-list-item-look">
            <div class="score" v-if="item.userScore">{{ item.userScore }}</div>
            <!-- 小狗 青蛙 小鹿 小儿郎 -->
            <template v-else>
              <img
                v-if="zoom == '小儿郎'"
                class="photo"
                src="https://res.cdn.quyixian.com/front/小儿郎.png"
                alt=""
              />
              <img
                v-else-if="zoom == '青蛙'"
                class="photo"
                src="https://res.cdn.quyixian.com/front/gua.png"
                alt=""
              />
              <img
                v-else-if="zoom == '长颈鹿'"
                class="photo"
                src="https://res.cdn.quyixian.com/front/chang.png"
                alt=""
              />
              <img
                v-else
                class="photo"
                src="https://res.cdn.quyixian.com/front/dog.png"
                alt=""
              />
            </template>
            <p
              class="sentence"
              v-html="item.evaWord != '' ? item.evaWord : item.word"
            ></p>
          </div>
        </template>
      </div>
    </div>
    <van-loading v-else size="24px" vertical>加载中...</van-loading>
    <audio id="audio" ref="audio" style="height: 0" :src="filePath"></audio>
    <audio
      id="partAudio"
      ref="partAudio"
      style="height: 0"
      :src="partFilePath"
    ></audio>
    <div id="fixed" class="recorder-fixed">
      <canvas id="canvas"></canvas>
    </div>
  </div>
</template>

<script>
// import WaveSurfer from "wavesurfer.js";
import { Dialog, Toast } from "vant";
import Recorder from "js-audio-recorder";
import oraWx from "@/assets/js/orawx";

export default {
  name: "recorder",
  data() {
    return {
      //参数
      resid: this.$route.query.rid == undefined ? "" : this.$route.query.rid, //资源ID
      bookid: this.$route.query.bid == undefined ? "" : this.$route.query.bid, //图书ID
      resname:
        this.$route.query.rname == undefined ? "" : this.$route.query.rname, //资源名称
      bookname:
        this.$route.query.bname == undefined ? "" : this.$route.query.bname, //图书名称
      memory:
        this.$route.query.memory == undefined ? "" : this.$route.query.memory, //是否词汇速记-  1是  2否
      zoom: this.$route.query.zoom == undefined ? "" : this.$route.query.zoom, //封面
      code: this.$route.query.code,
      userid: this.$cookie.getCookie("userid"), //用户ID
      baseHref: window.location.href, //当前页面URL

      //录音对象
      rec: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
      }),
      //跟读原文件
      filePath: "",
      //段落源文件  "https://res.cdn.quyixian.com/book_res/45-文章_1681976292185_1684467176662.mp3"
      partFilePath: "",
      //列表数据
      lrcList: [],
      audiocurrNumber: 0, //当前点击段落序号
      audioStart: 0, //段落开始时间
      audioEnd: 0, //段落结束时间
      audioEndAdd: 0, //结束框量
      //
      currentTime: "00:00", //当前时长
      totalTime: "00:00", //总时长
      progress: 0, //进度
      //波浪图-录音
      drawRecordId: null,
      oCanvas: null,
      ctx: null,
      //做状态切换
      loading: false,
      fileOfWav: "", //存储录音文件

      province: "", //省份
      city: "", //市
      district: "", //县
      address: "", //详细地址
    };
  },
  watch: {
    //监听录音-判断超长终止
    rec: {
      handler: (nVal, oVal) => {
        //console.log(nVal);
        if (nVal.duration >= 60) {
          Toast("太长了自动停止");
          this.stopRecordAudio();
        }
      },
      deep: true,
    },
    filePath: {
      handler: (nVal, oVal) => {
        let audio = document.getElementById("audio");
        setTimeout(() => {
          audio.load();
          audio.currentTime = 0;
        }, 300);
      },
      deep: true,
    },
  },
  mounted() {
    const that = this;
    //临时使用
    // this.userid = "oKw74t9Ak26ASFGQ-lD0i5cNUyIg";
    // this.getlrclist();
    // console.log(this.userid);
    //登录校验
    if (!this.userid) {
      if (this.code) {
        //接口 code换openid
        this.getuserid();
        //获取相关
        this.getlrclist();
        //获取位置信息
        this.location();
      } else {
        oraWx(this.baseHref); //静默授权
      }
    } else if (this.resid && this.bookid) {
      //获取相关
      this.getlrclist();
    } else {
      Dialog.alert({
        title: "未找到相关数据",
        message: "抱歉！数据可能走丢了，请退出，重新进入",
      }).then(() => {
        window.history.go(-1);
      });
    }
    // //尝试获取一次录制权限
    Recorder.getPermission().then(
      () => {},
      (error) => {
        Toast("无权录制");
      }
    );
    //
    this.oCanvas = document.getElementById("canvas");
    this.ctx = this.oCanvas.getContext("2d");
    //监听全文播放音频
    document
      .getElementById("audio")
      .addEventListener("timeupdate", function () {
        let current = this.currentTime; //当前时间
        let duration = this.duration; //总时间
        let end = parseFloat(that.audioEnd) + that.audioEndAdd; //结束时间+框量
        if (that.audioEnd > 0 && (end <= current || current >= duration)) {
          this.pause();
          //停止朗读
          that.lrcList[that.audiocurrNumber].isReading = false;
        }
      });
    //监听段落播放音频
    document
      .getElementById("partAudio")
      .addEventListener("timeupdate", function () {
        //输出信息
        if (this.currentTime >= this.duration) {
          that.lrcList[that.audiocurrNumber].isAudioing = false;
        }
      });
  },
  methods: {
    //列表跟读切换
    clickItem(index) {
      if (this.lrcList[index].isOpen == false) {
        // if(this.lrcList[i].endTime == "" && this.lrcList[i].endTime <= 0){
        //   Toast("数据还未加载完毕");
        // }else{
        for (let i = 0; i < this.lrcList.length; i++) {
          this.lrcList[i].isOpen = false;
          //操作当前节点
          if (i == index) {
            this.lrcList[i].isOpen = true;
            //console.log(this.lrcList[i]);
            this.audiocurrNumber = index; //记录当前点击的列表序列
            this.audioStart = this.lrcList[i].startTime;
            this.audioEnd = this.lrcList[i].endTime;
          }
        }
        //播放原音
        this.toReadPlay(true);
      }
    },
    //暂停播放原音
    clickReadPause() {
      let audio = this.$refs.audio;
      audio.pause();
      //更改朗读状态
      this.lrcList[this.audiocurrNumber].isReading = false;
    },
    //播放，暂停---朗读音频文件/已选中二次点读
    clickReadPlay() {
      this.toReadPlay(false);
    },
    toReadPlay(state) {
      let audio = this.$refs.audio;
      //更改朗读状态
      this.lrcList[this.audiocurrNumber].isReading = true;
      //设置当前播放开始时间 - 如果暂停过 继续播放
      if (
        state ||
        (!state &&
          (parseFloat(this.audioEnd) + this.audioEndAdd <= audio.currentTime ||
            audio.currentTime >= audio.duration))
      ) {
        if (this.isAndroid()) {
          audio.currentTime = this.audioStart;
        } else {
          //IOS播放故障
          document.getElementById("audio").currentTime = this.audioStart;
        }
      }
      audio.pause();
      audio.volume = 1;
      setTimeout(() => {
        audio.play();
      }, 100);
    },
    //已选中-开始播放录音
    clickItemPlayRecorder() {
      this.partFilePath = this.lrcList[this.audiocurrNumber].audioRecordUrl; //装入文件
      //this.partFilePath =
      ("https://res.cdn.quyixian.com/OralEvaluate/1686536675210_1686536676647.wav");

      if (this.partFilePath) {
        this.lrcList[this.audiocurrNumber].isAudioing = true;
        let partAudio = this.$refs.partAudio;
        partAudio.volume = 1;
        partAudio.pause();
        setTimeout(() => {
          partAudio.play();
        }, 100);
      } else {
        Toast("未找到录音");
      }
    },
    //已选中-停止播放录音
    clickItemPauseRecorder() {
      let partAudio = this.$refs.partAudio;
      partAudio.pause();
      //更新状态
      this.lrcList[this.audiocurrNumber].isAudioing = false;
    },
    //开始录音
    startRecordAudio(isreading, isaudioing) {
      let that = this;
      if (isreading || isaudioing) {
        Toast("请听完");
      } else {
        Recorder.getPermission().then(
          () => {
            //拿到指定点开位置
            document
              .getElementById("mxy" + this.audiocurrNumber)
              .appendChild(document.getElementById("canvas"));
            //console.log(this.rec.vol());
            this.rec.start(); // 开始录音
            Toast("开始录音");
            setTimeout(() => {
              that.lrcList[that.audiocurrNumber].isRecodering = true; //切换录音状态-开始录音
              that.drawRecord(); //开始绘制图片
            }, 500);
          },
          (error) => {
            //提示
            Dialog.alert({
              title: "网页授权",
              message: "请先允许该网页使用麦克风",
              confirmButtonText: "刷新",
            }).then(() => {
              window.location.replace(window.location.href);
            });
            console.log(`${error.name} : ${error.message}`);
          }
        );
      }
    },
    //停止录音
    stopRecordAudio() {
      this.rec.stop();
      //停止绘制
      this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
      this.drawRecordId = null;
      // fixed-放回去
      document
        .getElementById("fixed")
        .appendChild(document.getElementById("canvas"));
      //切换录音状态-停止录音
      this.lrcList[this.audiocurrNumber].isRecodering = false;
      //朗读中禁止播放段落录音
      this.lrcList[this.audiocurrNumber].isAudioing = false;
      console.log("停止录音");
      Toast("识别中...");
      //处理数据
      this.uploadWAVData();
    },
    //
    //上传wav录音数据
    uploadWAVData() {
      let that = this;
      let wavBlob = this.rec.getWAVBlob();
      // 创建一个formData对象
      let formData = new FormData();
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([wavBlob], { type: "audio/wav" });
      //获取当时时间戳作为文件名
      const fileOfBlob = new File([newbolb], new Date().getTime() + ".wav");
      this.fileOfWav = fileOfBlob;
      formData.append("file", fileOfBlob);
      //1上传文件 2024.12用于切换新接口
      this.$api.mary.newUploadFile(formData).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          //保存录音文件
          //Toast(data);
          this.lrcList[this.audiocurrNumber].audioRecordUrl = data;
          //2获取得分
          this.getOra();
        } else {
          console.log(message);
          Toast.fail("上传录音失败");
        }
      });
    },
    //分:秒.毫秒
    stringifySecond(v) {
      let m = parseInt(v / 60);
      let s = parseInt(v % 60);
      return (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
    },
    //秒
    stringifySecond1(v) {
      //00:10.100
      let reg = RegExp(/:/);
      let regs = RegExp(/./);
      let minute = 0; //分
      let second = 0;
      let mill = 0;
      if (reg.exec(v)) {
        minute = v.split(":")[0];
        second = v.split(":")[1].split(".")[0];
      }
      if (regs.exec(v)) {
        mill = v.split(".")[1];
        second = v.split(".")[0].split(":")[1];
      }
      //return (minute * 60 + second) * 1000 + mill;
      return minute * 60 + second * 1 + mill / 1000;
    },
    //获取跟读列表与源文件
    getlrclist() {
      let params = {
        resid: this.resid,
        bookid: this.bookid,
        userid: this.userid,
      };
      this.$api.mary.getFollowList(params).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          this.filePath = data.filePath + "?t=" + Date.now();
          let arr = [];
          data.followList.map((item) => {
            arr.push(Object.assign(item, { isReading: false })); //是否在朗读
          });
          this.lrcList = arr;
          this.loading = true;
          //console.log(arr);
        } else {
          //获取跟读数据失败请回到上一页面
        }
      });
    },
    //保存用户跟读数据
    saveUserRecorder() {
      let formData = new FormData();
      formData.append("userid", this.userid);
      formData.append("bookid", this.bookid);
      formData.append("bookname", this.bookname);
      formData.append(
        "resourceid",
        this.lrcList[this.audiocurrNumber].resourceId
      );
      formData.append("resourceName", this.resname);
      formData.append("followreadid", this.lrcList[this.audiocurrNumber].id);
      formData.append(
        "followreadword",
        this.lrcList[this.audiocurrNumber].word
      );
      formData.append("evaword", this.lrcList[this.audiocurrNumber].evaWord);
      formData.append("score", this.lrcList[this.audiocurrNumber].userScore);
      //2024.11.25 更新保存录音文件URL
      formData.append(
        "audioRecordUrl",
        this.lrcList[this.audiocurrNumber].audioRecordUrl
      );
      formData.append("province", this.province);
      formData.append("city", this.city);
      formData.append("area", this.district);
      formData.append("address", this.address);
      console.log(formData);
      //2024.11.25 更新保存接口
      this.$api.mary.newSaveScore(formData).then((res) => {
        let { tag, data, message } = res;
        console.log(data);
        if (tag == 1) {
          //2024.11.25 更新
          //保存成功
        } else {
          console.log(message);
        }
      });
    },
    //获取openid
    getuserid() {
      let params = {
        code: this.code,
      };
      this.$api.mary.getopenId(params).then((res) => {
        let { data, tag, message } = res;
        if (tag == 1) {
          this.userid = data;
          this.$cookie.clearCookie("userid");
          this.$cookie.setCookie("userid", data);
        } else {
          //登录失败？
          Toast("登录失败");
          console.log(message);
        }
      });
    },
    //绘制波浪图-录音
    drawRecord() {
      // 用requestAnimationFrame稳定60fps绘制
      this.drawRecordId = requestAnimationFrame(this.drawRecord);

      // 实时获取音频大小数据
      let dataArray = this.rec.getRecordAnalyseData(),
        bufferLength = dataArray.length;

      // 填充背景色
      this.ctx.fillStyle = "rgb(255, 255, 255)";
      this.ctx.fillRect(0, 0, this.oCanvas.width, this.oCanvas.height);

      // 设定波形绘制颜色
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "rgb(181 181 181)";

      this.ctx.beginPath();

      var sliceWidth = (this.oCanvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
        x = 0; // 绘制点的x轴位置

      for (var i = 0; i < bufferLength; i++) {
        var v = dataArray[i] / 128.0;
        var y = (v * this.oCanvas.height) / 2;

        if (i === 0) {
          // 第一个点
          this.ctx.moveTo(x, y);
        } else {
          // 剩余的点
          this.ctx.lineTo(x, y);
        }
        // 依次平移，绘制所有点
        x += sliceWidth;
      }

      this.ctx.lineTo(this.oCanvas.width, this.oCanvas.height / 2);
      this.ctx.stroke();
    },
    isAndroid() {
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        return true;
      }
    },
    //获取省份
    location() {
      this.$wechat.getLocation().then((res) => {
        let { latitude, longitude, errMsg } = res;
        if (errMsg == "getLocation:ok") {
          this.$api.mary
            .getWxAddres({
              location: latitude + "," + longitude,
            })
            .then((res) => {
              let { tag, data, message } = res;
              console.log(res);
              if (tag == 1 && data.message == "query ok") {
                this.province = data.result.address_component.province;
                this.city = data.result.address_component.city;
                this.district = data.result.address_component.district;
                this.address = data.result.address;
              }
            });
        }
      });
    },
    //获取得分
    getOra() {
      let that = this;
      this.$api.mary
        .newGetOraEvalute({
          oraltext: that.lrcList[that.audiocurrNumber].word,
          audioRecordUrl: that.lrcList[that.audiocurrNumber].audioRecordUrl,
        })
        .then((res) => {
          //Toast("完成打分");
          let { tag, data, message } = res;
          if (tag == 1) {
            //保存数据
            that.lrcList[that.audiocurrNumber].userScore = data.score;
            that.lrcList[that.audiocurrNumber].evaWord = data.oralText;
            //3保存得分
            that.saveUserRecorder();
          } else {
            console.log(message);
            Toast.fail("识别录音失败");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.van-button,
h3 {
  margin: 0.1rem 0.3rem;
}

.progress {
  margin: 0.3rem;

  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .van-slider {
    margin: 0.3rem 0;
  }
}
.lrclist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.28rem;

  margin: 0.3rem;
  &-item {
    width: 100%;
    text-align: center;
    line-height: 2;
  }
}

.recorder {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-h {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.36rem;
    margin-top: 0.3rem;
  }

  &-list {
    margin: 0 0.3rem;
    padding-bottom: 2.6rem;
    &-item {
      background: #eee;
      margin: 0.3rem 0;
      font-size: 0.36rem;
      // font-family: Arial, Helvetica, sans-serif;
      line-height: 1.6;
      padding: 0.3rem 0;
      border-radius: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &-look {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .score {
          height: 1rem;
          width: 1rem;
          border-radius: 1rem;
          font-size: 0.4rem;
          margin-left: 0.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #6ac9f3;
          border: 2px solid #6ac9f3;
        }

        .photo {
          height: 1rem;
          width: 1rem;
          border-radius: 1rem;
          margin-left: 0.3rem;
        }
        .sentence {
          flex: 1;
          text-align: center;
          margin: 0 0.3rem;
        }
      }

      &-read {
        background: #fff;
        width: 100%;
        margin: 0 0.3rem;
        border-radius: 0.3rem;

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.32rem;
          margin: 0.2rem;

          .number {
            color: #666;
            font-weight: bold;
            color: #6ac9f3;
          }
          .box {
            display: flex;
            align-items: center;

            ul {
              width: 50px;
              height: 20px;
              display: flex;
              transform: rotateX(180deg);
              justify-content: space-between;

              p {
                width: 5px;
                height: 0;
                background: #fff;
                border-radius: 4px;

                &:first-child {
                  animation: Mymove1 2s ease infinite;
                  animation-delay: 0.1s;
                  /* transform: rotateX(180deg) */
                }
                &:nth-of-type(2) {
                  animation: Mymove3 2s ease infinite;
                  animation-delay: 0.3s;
                }
                &:nth-of-type(3) {
                  animation: Mymove4 2s ease infinite;
                  animation-delay: 0.5s;
                }
                &:nth-of-type(4) {
                  animation: Mymove2 2s ease infinite;
                  animation-delay: 0.7s;
                }
                &:nth-of-type(5) {
                  animation: Mymove3 2s ease infinite;
                  animation-delay: 0.9s;
                }
                &:nth-of-type(6) {
                  animation: Mymove2 2s ease infinite;
                  animation-delay: 1.1s;
                }
                &:nth-of-type(7) {
                  animation: Mymove4 2s ease infinite;
                  animation-delay: 1.3s;
                }
                &:nth-of-type(8) {
                  animation: Mymove1 2s ease infinite;
                  animation-delay: 1.5s;
                }
              }
              @keyframes Mymove1 {
                0% {
                  height: 1px;
                }

                50% {
                  height: 20px;
                }

                100% {
                  height: 11px;
                }
              }
              @keyframes Mymove2 {
                0% {
                  height: 2px;
                }

                50% {
                  height: 20px;
                }

                100% {
                  height: 12px;
                }
              }
              @keyframes Mymove3 {
                0% {
                  height: 3px;
                }

                50% {
                  height: 20px;
                }

                100% {
                  height: 13px;
                }
              }
              @keyframes Mymove4 {
                0% {
                  height: 4px;
                }

                50% {
                  height: 20px;
                }

                100% {
                  height: 14px;
                }
              }
            }
          }

          .box-media {
            p {
              background: #fbb8ee !important;
            }
          }
          .box-recorder {
            p {
              background: #6ac9f3 !important;
            }
          }
          .box-audio {
            p {
              background: #ffd075 !important;
            }
          }

          .read {
            color: #6ac9f3;
            font-weight: bold;
            border: 2px solid #6ac9f3;
            border-radius: 0.5rem;
            width: 0.5rem;
            height: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 0.15rem;

          .photo {
            height: 1rem;
            width: 1rem;
          }
          .sentence {
            margin: 0 0.3rem 0.15rem 0.3rem;
          }
        }

        $mxy: 0.82rem;
        .func {
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 0.3rem;

          img {
            width: $mxy;
            height: $mxy;
          }

          &-fraction {
            width: $mxy;
            height: $mxy;
            background: url("https://res.cdn.quyixian.com/front/null.png")
              no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 0.24rem;
            font-weight: 500;
          }
          &-fraction1 {
            width: $mxy;
            height: $mxy;
            background: url("https://res.cdn.quyixian.com/front/not-null.png")
              no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 0.24rem;
            font-weight: 500;
          }
        }

        .fun {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          height: 1.2rem;
          margin-bottom: 0.15rem;

          .i-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            // box-shadow: 0 0 5px #ccc;
          }

          &-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            min-width: 1.2rem;

            p {
              min-height: 0.42rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &-media {
            color: #fbb8ee;
          }
          &-recording {
            color: #6ac9f3;
          }
          &-score {
            color: #ffd075;

            .i-score {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              background: #ffd075;
              color: #fff;
              font-size: 20px;
              height: 36px;
              width: 36px;
              font-weight: bold;
            }
            .i-score1 {
              //background: rgba($color: #ffd075, $alpha: 0.1);
              background: #fff;
              border: 1px dashed #ffd075;
            }
          }
        }

        .read-box {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  &-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.4);
  }
  &-fixed1 {
    height: 2rem;
    padding: 0.3rem 0;
  }
  #canvas {
    width: 90%;
    height: 1rem;
  }
}
</style>
