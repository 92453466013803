import Vue from "vue";
import api from "./api/install";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import "vant/lib/index.css";
import VueVideoPlayer from "vue-video-player";
require("../node_modules/video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
import wechat from "@/assets/js/wechat";
import utils from "@/assets/js/utils";
import cookie from "@/assets/js/cookie";
import { install } from "@icon-park/vue/es/all";
import animate from "animate.css";

// import VConsole from "vconsole";
// new VConsole();

Object.assign(Vue.prototype, {
  $cookie: cookie,
  $wechat: wechat,
  $utils: utils,
});

Vue.config.productionTip = false;

install(Vue, "i");
Vue.use(api).use(vant).use(VueVideoPlayer).use(animate);

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
