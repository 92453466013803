import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/book",
    name: "book",
    component: function () {
      return import("../views/book");
    },
  },
  {
    path: "/teachfolder",
    name: "teachFolder",
    component: function () {
      return import("../views/teachFolder"); //2024.5.16 新增
    },
  },
  {
    path: "/teachindex",
    name: "teachindex",
    component: function () {
      return import("../views/teachIndex"); //2024.12.23新增
    },
  },
  {
    path: "/folder",
    name: "folder",
    component: function () {
      return import("../views/folder");
    },
  },
  {
    path: "/bookpackge",
    name: "bookpackge",
    component: function () {
      return import("../views/bookpackge");
    },
  },
  {
    path: "/resource",
    name: "resource",
    component: function () {
      return import("../views/resource");
    },
  },
  {
    path: "/propose",
    name: "propose",
    component: function () {
      return import("../views/propose");
    },
  },
  {
    path: "/vbook",
    name: "vbook",
    component: function () {
      return import("../views/vbook"); //视频解析-图书页面
    },
  },
  {
    path: "/vfolder",
    name: "vfolder",
    component: function () {
      return import("../views/vfolder"); //视频解析-目录页面
    },
  },
  {
    path: "/remember",
    name: "remember",
    component: function () {
      return import("../views/follow/remember"); //跟读功能-词汇速记页
    },
  },
  {
    path: "/recorderlist",
    name: "recorderlist",
    component: function () {
      return import("../views/follow/recorderList"); //跟读功能-列表页
    },
  },
  {
    path: "/recorder",
    name: "recorder",
    component: function () {
      return import("../views/follow/_new_recorder"); //跟读功能-跟读页  2025.1.13修改指向/recorder无用
    },
  },
  {
    path: "/recorderNew",
    name: "recorderNew",
    component: function () {
      return import("../views/follow/_new_recorder"); //跟读功能-跟读页
    },
  },
  {
    path: "/recorderdemo",
    name: "recorderdemo",
    component: function () {
      return import("../views/recorderDemo"); //跟读功能-demo
    },
  },
  {
    path: "/rindex",
    name: "readindex",
    component: function () {
      return import("../views/read/index"); //跟读首页
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
