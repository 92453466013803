<template>
  <div class="viewpdf">
    <div class="viewpdf-video" v-if="playState">
      <mvideo v-if="res.fileExtendNameVideo == '.mp4'" :cur="curr"></mvideo>
      <img
        style="width: 100%; display: flex"
        v-else
        :src="res.filePathVideo"
        alt=""
      />
    </div>
    <div class="primary-pdf">
      <div class="primary-pdf-pos">PDF文件加载中，稍等片刻！</div>
      <div id="pdf"></div>
      <div class="primary-pdf-footer">
        <div class="count">
          <img src="../assets/images/page.png" alt="" />
          <span>{{ count }}</span>
        </div>
        <div v-if="list && list.length > 0" class="list" @click="listShow">
          <img src="../assets/images/list.png" alt="" />
          <span>目录</span>
        </div>
        <!-- <a v-if="iswx" class="down" @click="wxGuide">
          <img src="../assets/images/down.png" alt="" />
          <span>下载</span>
        </a> -->
        <a target="_blank" :href="res.filePath" class="down" @click="down">
          <img src="../assets/images/down.png" alt="" />
          <span>下载</span>
        </a>
      </div>
      <van-popup
        class="mxy-popup"
        round="round"
        closeable
        v-model="listSwitch"
        position="bottom"
        :style="{ height: '50%' }"
        @click-overlay="listSwitch = false"
        @click-close-icon="listSwitch = false"
      >
        <ul v-if="list.length > 0" ref="popupList" class="mxy-popup-list">
          <li
            v-for="(item, index) in list"
            ref="listItem"
            @click="itemClick(index)"
            :class="index == currListNum ? 'cur' : ''"
            :key="item.rid"
          >
            <p>{{ item.fileName }}</p>
          </li>
        </ul>
        <p v-else class="mxy-popup-no">目录走丢了...</p>
      </van-popup>
      <van-popup class="mxy-guide" v-model="iswxshow" position="top">
        <img src="../assets/images/lead.png" alt="" />
      </van-popup>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import ax from "axios";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
import mvideo from "@/components/mvideo.vue";

export default {
  name: "viewpdf",
  components: { mvideo },
  props: ["res"],
  data() {
    return {
      iswx: false,
      iswxshow: false,
      pdfh5: "",
      logoUrl: "small-logo.png",
      count: 0,
      listSwitch: false,
      list: [],
      currListNum: 0,
      box: [], //临时装数据使用

      playState: false,
      //当前播放信息
      curr: {},
    };
  },
  watch: {
    box: {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.listLoadingShow = true;
          this.list.length = 0;
          if (typeof this.box.data.resList === "object") {
            this.list = Object.values(this.box.data.resList);
          } else {
            this.list = this.box.data.resList;
          }
          if (this.list.length > 0) {
            for (let i = 0; i < this.list.length; i++) {
              let row = this.list[i];
              if (this.res.rid == row.rid) {
                this.currListNum = i;
              }
            }
          }
          //console.log(this.list);
        }
      },
    },
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf("micromessenger") != -1;
    if (isWeixin) {
      this.iswx = true;
    } else {
      this.iswx = false;
    }
    console.log(this.res);

    if (this.res.filePath != "" && this.res.filePath != undefined) {
      this.getIOPdf(this.res.filePath);
      this.getList();

      if (this.res.filePathVideo != "") {
        this.curr = {
          id: this.res.id,
          nav: "",
          url: this.res.filePathVideo,
          level: this.res.levels,
        };
        this.playState = true;
      }
    }
  },
  methods: {
    getIOPdf(url) {
      let that = this;
      ax.get(url, {
        responseType: "arraybuffer",
      }).then((res) => {
        //console.log(res);
        that.pdfh5 = new Pdfh5("#pdf", {
          data: res.data,
          // logo: {
          //   src: this.logoUrl,
          //   x: 0,
          //   y: 0,
          //   width: 50,
          //   height: 50,
          // }
        });
        that.pdfh5.on("ready", function () {
          that.count = this.totalNum;
        });
        //监听完成事件
        // this.pdfh5.on("complete", function (status, msg, time) {
        //   console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        // })
      });
    },

    //下载记录
    down() {
      //父级事件
      this.$emit("down");
    },
    wxGuide() {
      this.iswxshow = true;
    },

    //显示目录
    listShow() {
      this.listSwitch = true;
      //控制位置
      setTimeout(() => {
        let currLi = this.$refs.listItem[this.currListNum].offsetHeight;
        this.$refs.popupList.scrollTop = this.currListNum * currLi;
      }, 100);
    },

    //选择资源
    itemClick(index) {
      this.listSwitch = false;
      this.currListNum = index;
      let curr = this.list[index];
      document.title = curr.fileName + "-" + this.res.bookName;
      this.res.filePath = curr.filePath;
      this.getIOPdf(curr.filePath);
    },

    //获取数据列表
    async getList() {
      const folders = sessionStorage.getItem(this.res.bid);
      if (folders == null) {
        sessionStorage.clear();
        let params = {
          bid: this.res.bid,
          fid: this.res.fid,
        };
        let info = await this.$api.mary.GetFolderInfo(params);
        sessionStorage.setItem(info.data.bid, qs.stringify(info));
        this.box = info;
      } else {
        this.box = qs.parse(sessionStorage.getItem(this.res.bid));
      }
    },
  },
};
</script>
<style lang="scss">
.viewpdf {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}
.viewpdf-video {
  width: 100%;
  z-index: 999;
  background: #fff;
  & > div {
    width: 100%;
  }
}
</style>
