var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vv"},[(_vm.loading)?[_c('div',{staticClass:"vv-head"},[(_vm.playState)?_c('mvideo',{attrs:{"cur":_vm.curr}}):_c('div',[(_vm.book.videoCoverImg)?_c('van-image',{attrs:{"src":_vm.book.videoCoverImg}}):_c('van-image',{attrs:{"src":"https://res.cdn.quyixian.com/video_cover/banner.jpg"}})],1)],1),(_vm.book.slogan)?[(
          _vm.currCountRes == 0 ||
          (_vm.isCurrCountRes > 0 && _vm.currCountRes - _vm.isCurrCountRes > 0)
        )?_c('div',{staticClass:"vv-ttt"},[(_vm.book.bookName)?_c('div',[_vm._v("书名："+_vm._s(_vm.book.bookName))]):_vm._e(),(_vm.book.fullName)?_c('div',[_vm._v("上级目录："+_vm._s(_vm.book.fullName))]):_vm._e(),(_vm.book.folderName)?_c('div',[_vm._v("当前目录："+_vm._s(_vm.book.folderName))]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.book.slogan))])]):_vm._e()]:_vm._e(),(_vm.book.videoFolderShow == 0)?_c('div',{staticClass:"vv-list"},[_c('van-collapse',{attrs:{"border":false,"accordion":""},model:{value:(_vm.listNames),callback:function ($$v) {_vm.listNames=$$v},expression:"listNames"}},[_c('van-collapse-item',{staticClass:"vv-folder",attrs:{"border":false,"title":_vm.book.folderName,"name":0}},[(_vm.book.folderList.length > 0)?_c('van-collapse',{attrs:{"accordion":""},model:{value:(_vm.itemNames),callback:function ($$v) {_vm.itemNames=$$v},expression:"itemNames"}},_vm._l((_vm.book.folderList),function(item,index){return _c('van-collapse-item',{key:item.id,staticClass:"vv-folder-item",attrs:{"name":index,"title":item.folderName}},[(item.childrenCount > 0 && item.childrenList.length > 0)?_c('van-collapse',{attrs:{"accordion":""},model:{value:(_vm.itemsNames),callback:function ($$v) {_vm.itemsNames=$$v},expression:"itemsNames"}},_vm._l((item.childrenList),function(j,i){return _c('van-collapse-item',{key:j.id,staticClass:"vv-folder-item vv-folder-item1",staticStyle:{"font-size":"0.24rem"},attrs:{"name":i,"title":j.folderName}},[(j.resList.length > 0)?_c('div',{staticClass:"vv-reslist"},_vm._l((j.resList),function(res){return _c('div',{key:res.rid,staticClass:"vv-reslist-item",on:{"click":function($event){return _vm.playerCurr(
                          res.rid,
                          res.bid,
                          res.fid,
                          res.filePath,
                          _vm.book.folderName +
                            '>' +
                            item.folderName +
                            '>' +
                            j.folderName +
                            '>' +
                            res.fileName,
                          res.levels,
                          j.resList
                        )}}},[(_vm.curr.id == res.rid)?_c('van-icon',{attrs:{"name":"pause"}}):_c('van-icon',{attrs:{"name":"play"}}),_c('p',{class:_vm.curr.id == res.rid ? 'cur' : ''},[_vm._v(" "+_vm._s(res.fileName)+" ")])],1)}),0):_vm._e()])}),1):(item.resList.length > 0)?_c('div',{staticClass:"vv-reslist"},_vm._l((item.resList),function(res){return _c('div',{key:res.rid,staticClass:"vv-reslist-item",on:{"click":function($event){return _vm.playerCurr(
                      res.rid,
                      res.bid,
                      res.fid,
                      res.filePath,
                      _vm.book.folderName +
                        '>' +
                        item.folderName +
                        '>' +
                        res.fileName,
                      res.levels,
                      item.resList
                    )}}},[(_vm.curr.id == res.rid)?_c('van-icon',{attrs:{"name":"pause"}}):_c('van-icon',{attrs:{"name":"play"}}),_c('p',{class:_vm.curr.id == res.rid ? 'cur' : ''},[_vm._v(" "+_vm._s(res.fileName)+" ")])],1)}),0):_vm._e()],1)}),1):(_vm.book.resList.length > 0)?_c('div',{staticClass:"vv-reslist"},_vm._l((_vm.book.resList),function(res){return _c('div',{key:res.rid,staticClass:"vv-reslist-item",on:{"click":function($event){return _vm.playerCurr(
                  res.rid,
                  res.bid,
                  res.fid,
                  res.filePath,
                  _vm.book.folderName + '>' + res.fileName,
                  res.levels,
                  _vm.book.resList
                )}}},[(_vm.curr.id == res.rid)?_c('van-icon',{attrs:{"name":"pause"}}):_c('van-icon',{attrs:{"name":"play"}}),_c('p',{class:_vm.curr.id == res.rid ? 'cur' : ''},[_vm._v(" "+_vm._s(res.fileName)+" ")])],1)}),0):_vm._e()],1)],1)],1):[(_vm.book.slogan)?_c('div',{staticStyle:{"text-align":"center","margin-top":"1em"}},[_vm._v(" "+_vm._s(_vm.book.slogan)+" ")]):_vm._e()]]:[_c('van-loading',{staticClass:"vv-error",attrs:{"type":"spinner","color":"#FFA300"}},[_vm._v(_vm._s(_vm.loadingText))])],_c('van-popup',{attrs:{"round":"","close-on-click-overlay":false},model:{value:(_vm.cardShow),callback:function ($$v) {_vm.cardShow=$$v},expression:"cardShow"}},[_c('div',{staticClass:"vv-cardPop"},[_c('p',{staticClass:"vv-cardPop-title"},[_vm._v("请添加专属学习顾问")]),_c('div',{staticClass:"vv-cardPop-imgbg"},[_c('div',[_c('van-image',{attrs:{"src":_vm.card}})],1)]),_c('p',{staticClass:"vv-cardPop-text"},[_vm._v("长按识别添加，仅首次登录需授权")])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }