<template>
  <div class="primary-media">
    <div class="primary-media-head">
      <p style="white-space: pre-wrap">{{ res.bookName }}</p>
    </div>
    <div class="primary-media-main">
      <div class="primary-media-main-cover">
        <van-image
          :class="audioState == false ? 'cover-rotate-pause' : ''"
          round
          fit="cover"
          :src="res.bookCoverImg"
        />
      </div>
      <div class="primary-media-main-title">
        <p v-html="res.fileName"></p>
      </div>
      <div class="primary-media-main-progress">
        <div class="time">
          <div class="time-start">{{ currentTime }}</div>
          <div class="time-end">{{ totalTime }}</div>
        </div>
        <van-slider
          v-model="progress"
          @change="progressChange"
          active-color="#00B8F3"
          inactive-color="#CDCDD2"
        />
      </div>
      <div class="primary-media-main-joint" v-if="res.levelsMp3.length > 0">
        <div class="levelstitle">
          <p>选择节点</p>
          <p>共{{ res.levelsMp3.length }}节</p>
        </div>
        <div class="levelscontent">
          <div
            v-for="(l, index) in res.levelsMp3"
            :key="index"
            :class="l.state ? 'lev curr' : 'lev'"
            @click="clickLevels(index)"
          >
            {{ l.title }}
          </div>
        </div>
      </div>
      <div class="primary-media-main-func">
        <div class="back m-btn" @click="toggleSecond(true)">
          <img class="icon" src="../assets/images/left15.png" alt="" />
        </div>
        <div class="upper m-btn">
          <template v-if="upperShow">
            <img
              class="icon"
              @click="upperClick"
              src="../assets/images/upper.png"
              alt=""
            />
          </template>
          <template v-else>
            <img class="icon" src="../assets/images/upperGrey.png" alt="" />
          </template>
        </div>
        <div class="play m-btn" @click="togglePlaying">
          <img
            v-if="audioState"
            class="play-icon"
            src="../assets/images/pause.png"
            alt="播放"
          />
          <img
            v-else
            class="play-icon"
            src="../assets/images/play.png"
            alt="暂停"
          />
        </div>
        <div class="next m-btn">
          <template v-if="nextShow">
            <img
              class="icon"
              @click="nextClick"
              src="../assets/images/next.png"
              alt=""
            />
          </template>
          <template v-else>
            <img class="icon" src="../assets/images/nextGrey.png" alt="" />
          </template>
        </div>
        <div class="forward m-btn" @click="toggleSecond(false)">
          <img class="icon" src="../assets/images/right15.png" alt="" />
        </div>
      </div>
      <div class="primary-media-main-func">
        <div class="loop m-btn" @click="loopClick">
          <template v-if="loop">
            <img class="icon" src="../assets/images/loop.png" alt="" />
          </template>
          <template v-else>
            <img class="icon" src="../assets/images/loop1.png" alt="" />
          </template>
        </div>
        <div class="speed m-btn" @click="speedShow = true">
          <div class="speed-btn">{{ speedText }}</div>
        </div>
        <a v-if="iswx" @click="wxGuide" class="down m-btn">
          <img class="icon" src="../assets/images/newdown.png" alt="" />
        </a>

        <a
          v-else
          target="_blank"
          :href="res.filePath"
          @click="down"
          class="down m-btn"
        >
          <img class="icon" src="../assets/images/newdown.png" alt="" />
        </a>
        <div v-if="isAnswer" class="speed m-btn" @click="answerShow = true">
          <div class="speed-btn answer-btn">答案</div>
        </div>
        <div class="list m-btn" @click="listShow">
          <img class="icon" src="../assets/images/newlist.png" alt="" />
        </div>
      </div>
      <router-link
        v-if="res.hasFollowRead == 1"
        class="primary-media-main-follow"
        :to="{
          name: 'recorder',
          query: {
            bid: res.bid,
            rid: res.rid,
            memory: res.hasWordMemory,
            zoom: res.followIconName,
            rname: res.resName,
            bname: res.bookName,
          },
        }"
        >跟读练习</router-link
      >
      <audio
        id="audio"
        ref="audio"
        @play="onPlay"
        @pause="onPause"
        @ended="overAudio"
        style="height: 0"
        :src="res.filePath"
      ></audio>
      <van-popup v-model="speedShow" round position="bottom">
        <div class="speed-popup">
          <div class="speed-popup-title">请选择倍速</div>
          <ul class="speed-popup-list">
            <li
              :class="item.key == speed ? 'cur' : ''"
              v-for="item in speedList"
              @click="speedClick(item.key, item.value)"
              :key="item.key"
            >
              {{ item.value }}
            </li>
          </ul>
          <div class="speed-popup-close" @click="speedClose">关闭</div>
        </div>
      </van-popup>
      <van-popup
        class="mxy-popup"
        round="round"
        closeable
        v-model="listSwitch"
        position="bottom"
        :style="{ height: '50%' }"
        @click-overlay="listSwitch = false"
        @click-close-icon="listSwitch = false"
      >
        <ul v-if="list.length > 0" ref="popupList" class="mxy-popup-list">
          <li
            v-for="(item, index) in list"
            ref="listItem"
            @click="itemClick(index)"
            :class="index == currListNum ? 'cur' : ''"
            :key="item.rid"
          >
            <p>{{ index + 1 }}.{{ item.fileName }}</p>
          </li>
        </ul>
        <p v-else class="mxy-popup-no">目录走丢了...</p>
      </van-popup>
      <van-popup class="mxy-guide" v-model="iswxshow" position="top">
        <img src="../assets/images/lead.png" alt="" />
      </van-popup>
      <van-popup
        v-model="answerShow"
        position="bottom"
        :style="{ height: '80%' }"
      >
        <div></div>
        <div class="answerpdf" id="pdf"></div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
import ax from "axios";
import qs from "qs";

export default {
  name: "media",
  props: ["res"],
  data() {
    return {
      iswx: true,
      iswxshow: false,
      audioState: false, //播放状态
      currentTime: "00:00", //当前时长
      totalTime: "00:00", //总时长
      progress: 0, //进度
      loop: false, //循环方式 true列表循环 false单循环
      firstLoop: true, //是否首次加载
      speed: 1.0, //播放速度
      speedText: "倍速",
      speedShow: false,
      //PDF
      isAnswer: false,
      urlAnswer: "",
      answerShow: false,
      pdfh5: "",
      //
      speedList: [
        {
          key: 0.5,
          value: "0.5X",
        },
        {
          key: 0.75,
          value: "0.75X",
        },
        {
          key: 1,
          value: "1.0X",
        },
        {
          key: 1.25,
          value: "1.25X",
        },
        {
          key: 1.5,
          value: "1.5X",
        },
        {
          key: 2.0,
          value: "2.0X",
        },
      ],
      upperShow: true, //是否能上一首
      nextShow: true, //是否能下一首
      listSwitch: false,
      list: [], //目录
      currListNum: 0, //当前资源数
      totalListNum: 0, //列表总数
      box: [], //临时数据
      //播放次数
    };
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf("micromessenger") != -1;
    if (isWeixin) {
      this.iswx = true;
    } else {
      this.iswx = false;
    }
    console.log(this.res);
    let that = this;
    that.togglePlaying();
    that.getList();

    // let audio = this.$refs.audio;
    // audio.currentTime = 17.999;

    //PDF
    if (
      this.res &&
      this.res.hasAnswer &&
      this.res.hasAnswer == 1 &&
      this.res.filePathAnswer
    ) {
      this.isAnswer = true;
      this.urlAnswer = this.res.filePathAnswer;
    }

    //监听播放
    document
      .getElementById("audio")
      .addEventListener("timeupdate", function () {
        let current = this.currentTime;
        let duration = this.duration;
        //设置进度
        that.progress = (current / duration) * 100;
        //设置播放时长
        that.currentTime = that.stringifySecond(current);
        that.totalTime = that.stringifySecond(duration);
        console.log(
          "当前毫秒：" +
            current +
            ",总毫秒：" +
            duration +
            ",进度：" +
            that.progress
        );
      });
  },
  watch: {
    answerShow: {
      //PDF
      handler(newV, oldV) {
        if (newV == true) {
          this.getIOPdf(this.urlAnswer);
        }
      },
    },
    box: {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.list.length = 0;
          if (typeof this.box.data.resList === "object") {
            this.list = Object.values(this.box.data.resList);
          } else {
            this.list = this.box.data.resList;
          }
          if (this.list.length > 0) {
            for (let i = 0; i < this.list.length; i++) {
              let row = this.list[i];
              if (this.res.rid == row.rid) {
                this.currListNum = i;
              }
            }
          }
          this.totalListNum = this.list.length;
          if (this.currListNum == 0) {
            this.upperShow = false;
          } else if (this.currListNum + 1 == this.totalListNum) {
            this.nextShow = false;
          } else {
            this.upperShow = true;
            this.nextShow = true;
          }
          //console.log(this.totalListNum);
          //console.log(this.currListNum);
        }
      },
    },
  },
  methods: {
    getIOPdf(url) {
      let that = this;
      ax.get(url, {
        responseType: "arraybuffer",
      }).then((res) => {
        //console.log(res);
        that.pdfh5 = new Pdfh5("#pdf", {
          data: res.data,
          // logo: {
          //   src: this.logoUrl,
          //   x: 0,
          //   y: 0,
          //   width: 50,
          //   height: 50,
          // }
        });
        that.pdfh5.on("ready", function () {
          that.count = this.totalNum;
        });
        //监听完成事件
        // this.pdfh5.on("complete", function (status, msg, time) {
        //   console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        // })
      });
    },

    //切换小节
    clickLevels(index) {
      //样式
      for (let i = 0; i < this.res.levelsMp3.length; i++) {
        this.res.levelsMp3[i].state = false;
        if (i == index) {
          this.res.levelsMp3[i].state = true;
        }
      }
      //设置播放时间
      let audio = this.$refs.audio;
      audio.currentTime = this.res.levelsMp3[index].seconds;
    },

    //播放，暂停
    togglePlaying() {
      let audio = this.$refs.audio;
      if (audio.paused) {
        setTimeout(() => {
          audio.play();
        }, 100);
      } else {
        audio.pause();
      }
    },
    //重播
    currentPlay() {
      let audio = this.$refs.audio;
      audio.currentTime = 0;
      setTimeout(() => {
        audio.play();
      }, 100);
    },
    //重播 停止 22.8.3
    currentPause() {
      let audio = this.$refs.audio;
      audio.currentTime = 0;
      // setTimeout(() => {
      //   audio.play();
      // }, 100)
    },
    //监听播放
    onPlay() {
      this.audioState = true;
    },
    //监听暂停
    onPause() {
      this.audioState = false;
    },
    //播放完毕
    overAudio() {
      if (this.loop) {
        this.nextClick();
      } else {
        //播放完毕且默认单曲循环下回滚暂停
        this.currentPause();
      }
    },
    //列表上一曲
    upperClick() {
      if (this.currListNum > 0) {
        this.currClick(this.currListNum - 1);
      } else {
        Toast("没有上一个啦！");
      }
    },
    //列表下一曲
    nextClick() {
      if (this.currListNum + 1 < this.totalListNum) {
        this.currClick(this.currListNum + 1);
      } else {
        Toast("没有下一个啦！");
      }
    },
    //点播一曲
    currClick(currNum) {
      //console.log(currNum);
      let curr = this.list[currNum];

      if (curr && curr.filePath) {
        this.currListNum = currNum; //设置当前播放位置
        if (this.currListNum == 0) {
          this.upperShow = false;
        } else if (this.currListNum + 1 == this.totalListNum) {
          this.nextShow = false;
        } else {
          this.upperShow = true;
          this.nextShow = true;
        }
        //bname: res.bookName,
        this.res.bid = curr.bid;
        this.res.rid = curr.rid;
        this.res.hasWordMemory = curr.hasWordMemory;
        this.res.followIconName = curr.followIconName;
        this.res.resName = curr.fileName;
        //
        this.res.fileName = curr.fileName;
        this.res.filePath = curr.filePath;
        this.res.hasFollowRead = curr.hasFollowRead;
        document.title = curr.fileName + "-" + this.res.bookName;
        console.log(curr);
        this.currentPlay();
      } else {
        Toast("资源不存在");
      }
    },
    //进度条
    progressChange(event) {
      let audio = this.$refs.audio;
      this.progress = event;
      audio.currentTime = audio.duration * (event / 100);
      //拖动播放
      // if (!this.audioState) {
      //   this.togglePlaying();
      // }
      //let duration = audio.duration;
      //let currentP = event;
      //let currentT = (duration) * (currentP / 100);
      //this.currentTime = this.stringifySecond(currentT); 监听会自动赋值
    },
    //快进 后退
    toggleSecond(is) {
      let audio = this.$refs.audio;
      let curr = audio.currentTime;
      let durr = audio.duration;
      if (is) {
        //后退15秒
        console.log("后退15秒");
        if (curr > 0) {
          audio.currentTime = curr - 15;
        }
      } else if (!is) {
        //前进15秒
        console.log("前进15秒");
        let co = curr + 15;
        if (co < durr) {
          audio.currentTime = co;
        } else {
          audio.currentTime = durr;
        }
      }
    },
    //循环模式
    loopClick() {
      //true 列表循环  false 单个循环
      if (this.loop) {
        this.loop = false;
        Toast("单曲循环");
      } else {
        this.loop = true;
        Toast("列表循环");
      }
    },
    //倍速设置
    speedClick(k, v) {
      this.speed = k;
      this.speedText = v;
      let audio = this.$refs.audio;
      audio.playbackRate = k;
    },
    speedClose() {
      this.speedShow = false;
    },

    //分秒转换秒
    // stringify1(v) {
    //   let min = v.split(':')[0];
    //   let sec = v.split(':')[1];
    //   return Number(min * 60) + Number(sec);
    // },
    //秒转换分秒
    //秒转分秒
    stringifySecond(v) {
      let m = parseInt(v / 60);
      let s = parseInt(v % 60);
      return (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
    },
    //下载
    down() {
      //父级事件
      this.$emit("down");
    },
    wxGuide() {
      this.iswxshow = true;
    },
    //展示列表
    listShow() {
      this.listSwitch = true;
      //控制位置
      setTimeout(() => {
        let currLi = this.$refs.listItem[this.currListNum].offsetHeight;
        this.$refs.popupList.scrollTop = this.currListNum * currLi;
      }, 100);
    },
    //目录选择具体资源
    itemClick(index) {
      this.listSwitch = false;
      this.currClick(index);
    },
    //获取数据列表
    async getList() {
      const folders = sessionStorage.getItem(this.res.bid);
      if (folders == null) {
        sessionStorage.clear();
        let params = {
          bid: this.res.bid,
          fid:
            this.res.fid == "642473711290552320"
              ? "642473707150774272"
              : this.res.fid, //2024.1.23印俩资源印同一码特殊处理目录ID查询,
        };
        let info = await this.$api.mary.GetFolderInfo(params);
        sessionStorage.setItem(info.data.bid, qs.stringify(info));
        this.box = info;
      } else {
        this.box = qs.parse(sessionStorage.getItem(this.res.bid));
      }
    },
  },
};
</script>

<style scoped>
.answerpdf {
  width: 100%;
  height: 100%;
}

.answer-btn {
  background: rgb(0, 184, 243);
  color: #fff;
  border-width: 0;
  box-shadow: 0 0 5px rgb(0, 184, 243);
}
</style>
