<template>
  <div class="primary-folder">
    <template v-if="loading">
      <div class="primary-folder-list">
        <div class="primary-folder-list-tit">
          <span>教师课件</span>
        </div>
        <div
          v-if="resList"
          class="primary-folder-list-data primary-folder-list-data2"
        >
          <div
            v-for="item in resList"
            :key="item.bId"
            @click="toLink(item.bId, item.fid)"
          >
            <img
              class="text"
              src="https://res.cdn.quyixian.com/front/text.png"
            />
            <div class="title title1">
              <p v-html="item.bookName"></p>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="nolist">没找到教师课件</div>
        </template>
      </div>
    </template>
    <template v-else>
      <template v-if="loadingExce">
        <div class="primary-nothing">
          <img class="cover" src="../assets/images/nothing.png" alt="" />
          <p class="title">您查找的内容不存在</p>
          <router-link
            :to="{
              name: 'propose',
              query: {},
            }"
            class="btn"
          >
            <img class="icon" src="../assets/images/ope.png" alt="" />
            我要反馈
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="primary-folder-no">
          <van-loading>{{ loadingText }}</van-loading>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { Dialog } from "vant";

export default {
  name: "teachfolder",
  data() {
    return {
      loading: false,
      loadingText: "解析中...",
      loadingExce: false,
      //
      yid: this.$route.query.yid || "",
      sid: this.$route.query.sid || "",
      kid: this.$route.query.kid || "",
      vid: this.$route.query.vid || "",
      //
      resList: [],
    };
  },
  watch: {},
  mounted() {
    //-----------------------------------------
    // this.yid = "751727082051997696";
    // this.sid = "427401077403422720";
    // this.kid = "427401213512781824";
    // this.vid = "436842030279168000";
    // this.getIndex();
    //-----------------------------------------
    //判断参数是否有值
    if (this.yid == "" || this.sid == "" || this.kid == "" || this.vid == "") {
      this.loading = false;
      this.loadingText = "解析URL出错啦...";
      this.loadingExce = true;
    } else {
      this.getIndex();
    }
  },
  methods: {
    //link
    toLink(b, f) {
      if (f) {
        this.$router.push({
          name: "teachFolder",
          query: { bid: b, fid: f },
        });
      } else {
        Dialog.alert({
          message: "该书还没有教师课件",
        });
      }
    },
    //获取index
    getIndex() {
      let params = {
        yid: this.yid,
        sid: this.sid,
        kid: this.kid,
        vid: this.vid,
      };
      this.$api.mary.getTeachIndex(params).then((res) => {
        let { tag, data } = res;
        if (tag == 1) {
          this.loading = true;
          //  &#10;
          // for (let i = 0; i < data.length; i++) {
          //   console.log(data[i]);
          // }
          this.resList = data;
        } else {
          this.loadingExce = true;
          this.loading = false;
          this.loadingText = "列表资源走丢了...";
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mxy-noDown {
  font-size: 0.45rem;
}
.downpopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.3rem;

  &-title {
    text-align: center;
    margin-top: 0.5rem;
  }
  &-bottom {
    display: flex;
    justify-content: center;
    margin: 0.15rem 0;
    padding-bottom: 0.3rem;
    border-bottom: 1px dotted #999;
    a {
      width: 3.7rem;
      padding: 0.15rem 0.6rem;
    }
  }
  &-mail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.3rem 0;

    .van-field {
      border: 1px solid #999;
      width: 80%;
      border-radius: 0.15rem;
    }
    .down {
      width: 5rem;
      margin-top: 0.15rem;
    }
  }

  .down {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6abd44;
    color: #fff;
    border-radius: 0.15rem;
  }
}
.primary-explain {
  padding: 0 1em;
  font-size: 0.32rem;
  color: red;
  line-height: 1.6;
  margin-bottom: 0.15rem;
}
</style>
