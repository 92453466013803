<template>
  <div class="vv">
    <template v-if="loading">
      <!-- <div>
        <van-sticky> -->
      <div class="vv-head">
        <mvideo v-if="playState" :cur="curr"></mvideo>
        <div v-else>
          <van-image v-if="book.videoCoverImg" :src="book.videoCoverImg" />
          <van-image
            v-else
            src="https://res.cdn.quyixian.com/video_cover/banner.jpg"
          />
        </div>
      </div>
      <!-- </van-sticky>
      </div> -->
      <template v-if="book.slogan">
        <div
          class="vv-ttt"
          v-if="
            currCountRes == 0 ||
            (isCurrCountRes > 0 && currCountRes - isCurrCountRes > 0)
          "
        >
          <div v-if="book.bookName">书名：{{ book.bookName }}</div>
          <div v-if="book.fullName">上级目录：{{ book.fullName }}</div>
          <div v-if="book.folderName">当前目录：{{ book.folderName }}</div>
          <p>{{ book.slogan }}</p>
        </div>
      </template>
      <div v-if="book.videoFolderShow == 0" class="vv-list">
        <!--第一层目录-->
        <van-collapse :border="false" v-model="listNames" accordion>
          <van-collapse-item
            :border="false"
            :title="book.folderName"
            :name="0"
            class="vv-folder"
          >
            <!--第二层目录-->
            <van-collapse
              v-model="itemNames"
              accordion
              v-if="book.folderList.length > 0"
            >
              <van-collapse-item
                class="vv-folder-item"
                v-for="(item, index) in book.folderList"
                :key="item.id"
                :name="index"
                :title="item.folderName"
              >
                <!--第三层目录-->
                <van-collapse
                  v-model="itemsNames"
                  accordion
                  v-if="item.childrenCount > 0 && item.childrenList.length > 0"
                >
                  <van-collapse-item
                    class="vv-folder-item vv-folder-item1"
                    style="font-size: 0.24rem"
                    v-for="(j, i) in item.childrenList"
                    :key="j.id"
                    :name="i"
                    :title="j.folderName"
                  >
                    <!-- 三级下直接资源 -->
                    <div class="vv-reslist" v-if="j.resList.length > 0">
                      <div
                        class="vv-reslist-item"
                        v-for="res in j.resList"
                        :key="res.rid"
                        @click="
                          playerCurr(
                            res.rid,
                            res.bid,
                            res.fid,
                            res.filePath,
                            book.folderName +
                              '>' +
                              item.folderName +
                              '>' +
                              j.folderName +
                              '>' +
                              res.fileName,
                            res.levels,
                            j.resList
                          )
                        "
                      >
                        <!-- 二层目录下资源 -->
                        <van-icon
                          v-if="curr.id == res.rid"
                          name="pause"
                        ></van-icon>
                        <van-icon v-else name="play"></van-icon>
                        <p :class="curr.id == res.rid ? 'cur' : ''">
                          {{ res.fileName }}
                        </p>
                      </div>
                    </div>
                  </van-collapse-item>
                </van-collapse>
                <!-- 二级下直接资源 -->
                <div class="vv-reslist" v-else-if="item.resList.length > 0">
                  <div
                    class="vv-reslist-item"
                    v-for="res in item.resList"
                    :key="res.rid"
                    @click="
                      playerCurr(
                        res.rid,
                        res.bid,
                        res.fid,
                        res.filePath,
                        book.folderName +
                          '>' +
                          item.folderName +
                          '>' +
                          res.fileName,
                        res.levels,
                        item.resList
                      )
                    "
                  >
                    <!-- 二层目录下资源 -->
                    <van-icon v-if="curr.id == res.rid" name="pause"></van-icon>
                    <van-icon v-else name="play"></van-icon>
                    <p :class="curr.id == res.rid ? 'cur' : ''">
                      {{ res.fileName }}
                    </p>
                  </div>
                </div>
              </van-collapse-item>
            </van-collapse>
            <!-- 一层目录下资源 -->
            <div class="vv-reslist" v-else-if="book.resList.length > 0">
              <div
                class="vv-reslist-item"
                v-for="res in book.resList"
                :key="res.rid"
                @click="
                  playerCurr(
                    res.rid,
                    res.bid,
                    res.fid,
                    res.filePath,
                    book.folderName + '>' + res.fileName,
                    res.levels,
                    book.resList
                  )
                "
              >
                <van-icon v-if="curr.id == res.rid" name="pause"></van-icon>
                <van-icon v-else name="play"></van-icon>
                <p :class="curr.id == res.rid ? 'cur' : ''">
                  {{ res.fileName }}
                </p>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <template v-else>
        <div style="text-align: center; margin-top: 1em" v-if="book.slogan">
          {{ book.slogan }}
        </div>
      </template>
    </template>
    <template v-else>
      <van-loading class="vv-error" type="spinner" color="#FFA300">{{
        loadingText
      }}</van-loading>
    </template>
    <van-popup round v-model="cardShow" :close-on-click-overlay="false">
      <div class="vv-cardPop">
        <p class="vv-cardPop-title">请添加专属学习顾问</p>
        <div class="vv-cardPop-imgbg">
          <div>
            <van-image :src="card"></van-image>
          </div>
        </div>
        <p class="vv-cardPop-text">长按识别添加，仅首次登录需授权</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
import wx from "../assets/js/wx";
import mvideo from "@/components/mvideo.vue";
export default {
  name: "vfolder",
  components: { mvideo },
  data() {
    return {
      listNames: 0,
      itemNames: 0,
      itemsNames: 0,
      bid: this.$route.query.bid,
      fid: this.$route.query.fid,
      code: this.$route.query.code,
      state: this.$route.query.state,
      loading: false,
      loadingText: "解析中...",
      loadingExce: false,
      book: {},
      userid: "",
      userPower: true, //true已授权 false未授权
      card: "https://res.cdn.quyixian.com/video_cover/card.png", //名片地址
      cardShow: false, //是否显示名片
      baseHref: window.location.href, //当前页面URL
      baseUrl:
        window.location.href.split("?")[0] +
        "?bid=" +
        this.$route.query.bid +
        "&fid=" +
        this.$route.query.fid, //原始的地址
      playState: false, //弹出视频播放器
      //当前播放信息
      curr: {},
      wxCheck: 0, //企业微信验证（默认为 0开启） 0、开启  1、关闭
      province: "未知地区", //省
      isCurrCountRes: 0, //当前目录下resList所有资源有几个未上传文件
      currCountRes: 0, //当前目录下resList所有资源

      isLandscape: false, //是否横屏状态切换
    };
  },
  watch: {
    userPower: {
      handler(newkey, oldkey) {
        if (newkey == false) {
          this.getCard();
        }
      },
    },
  },
  mounted() {
    //
    this.renderResize();
    // 监听resize方法
    window.addEventListener("resize", this.renderResize, false);
    //判断参数是否有值
    if (
      this.bid != undefined &&
      this.bid != "" &&
      this.fid != undefined &&
      this.fid != ""
    ) {
      //this.getResList();

      if (this.code && this.state == "status") {
        //this.location()
        this.getPower();
        //定时器，检测
        //3.23
        // let that = this
        // setInterval(function () {
        //   // console.log(that.userPower)
        //   // console.log(that.wxCheck)  //0开启企业微信认证  1不开启
        //   if (that.userPower == false && that.wxCheck == 0) {
        //     that.cardShow = true
        //   }
        // }, 5000)
      } else {
        wx(this.baseHref); //静默授权
      }
    } else {
      this.loading = false;
      this.loadingText = "解析URL出错啦...";
      this.loadingExce = true;
    }
  },
  beforeDestroy() {
    // 移除监听
    window.removeEventListener("resize", this.renderResize, false);
  },
  methods: {
    //判断当前横竖屏
    renderResize() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (width > height) {
        this.isLandscape = true;
      } else {
        this.isLandscape = false;
      }
      console.log(width, height);
      // 做页面适配
      // 注意：renderResize方法执行时虚拟dom尚未渲染挂载，如果要操作vue实例，最好在this.$nextTick()里进行。
    },

    //播放当前视频   播放视频地址未改
    playerCurr(id, bid, fid, url, nav, levels, list) {
      this.curr = {
        id: id,
        nav: nav,
        url: url,
        level: levels,
        list: list,
      };
      this.playState = true;

      //1图书 2目录
      let params = {
        bid: bid,
        fid: fid,
        rid: id,
        source: 2,
      };
      let header = {
        qyx_user_id: this.userid,
      };
      this.$api.mary.vResCount(params, header);
    },
    //获取目录及资源
    getResList() {
      this.loading = false;
      let params = {
        bid: this.bid,
        fid: this.fid,
      };
      let header = {
        qyx_user_id: this.userid,
      };
      this.$api.mary.getvlist(params, header).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          this.loading = true;
          this.book = data;
          this.wxCheck = data.wxCheck;
          //console.log(data)
          document.title = data.bookName;
          if (data.fullName) {
            data.fullName = data.fullName.replace("##", "");
          }
          //判断目录下的资源列表是否上传资源
          if (this.book.resList || this.book.resList.length > 0) {
            this.currCountRes =
              this.book.resList.length == 0 ? 0 : this.book.resList.length + 1;
            for (let i = 0; i < this.book.resList.length; i++) {
              let item = this.book.resList[i].filePath;
              if (item == "") {
                this.isCurrCountRes += 1;
              }
            }
            console.log(
              "目录下总资源数：" +
                this.currCountRes +
                "，缺少资源文件数：" +
                this.isCurrCountRes +
                "。"
            );
          }
        } else {
          this.loading = false;
          this.loadingText = "获取数据失败啦...!";
          Dialog({
            title: "获取数据失败",
            message: message,
          });
        }
      });
    },
    //获取用户企业微信授权信息
    getPower() {
      let params = {
        code: this.code,
        state: this.state,
        bookId: this.bid,
      };
      this.$api.mary.getWxLogin(params).then((res) => {
        //console.log(res)
        let { tag, data, message } = res;
        if (tag == 1) {
          this.userid = data.qyx_user_id;
          //userid 作为授权判断
          // 3.23
          // if (data.external_userid) {
          //   this.userPower = true
          // } else {
          //   this.userPower = false
          // }
          //修正地址栏URL
          history.replaceState(null, null, this.baseUrl);

          //登录后获取资源
          this.getResList();
        } else {
          Dialog({
            title: "认证失败",
            message: message,
            confirmButtonText: "刷新页面",
          }).then(() => {
            window.location.replace(this.baseUrl);
          });
        }
      });
    },
    //获取企业微信名片
    getCard() {
      let params = {
        bookId: this.bid,
        province: "",
      };
      this.$api.mary.getAddContactWay(params).then((res) => {
        let { tag, data, message } = res;
        if (tag == 1) {
          this.card = data.qr_code;
        } else {
          Dialog({
            title: "获取名片失败",
            message: message,
            confirmButtonText: "刷新页面",
          }).then(() => {
            window.location.replace(this.baseUrl);
          });
        }
      });
    },
    //获取省份
    location() {
      this.$wechat.getLocation().then((res) => {
        let { latitude, longitude, errMsg } = res;
        if (errMsg == "getLocation:ok") {
          this.$api.mary
            .getWxAddres({
              location: latitude + "," + longitude,
            })
            .then((res) => {
              let { tag, data, message } = res;
              //console.log(res)
              if (tag == 1 && data.message == "query ok") {
                this.province = data.result.address_component.province;
              }
            });
        }
        //确保弹出后定位信息后再执行
      });
    },
  },
};
</script>
